// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boutique-js": () => import("./../../../src/pages/boutique.js" /* webpackChunkName: "component---src-pages-boutique-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-propos-js": () => import("./../../../src/pages/propos.js" /* webpackChunkName: "component---src-pages-propos-js" */),
  "component---src-templates-artpiece-js": () => import("./../../../src/templates/artpiece.js" /* webpackChunkName: "component---src-templates-artpiece-js" */),
  "component---src-templates-sale-js": () => import("./../../../src/templates/sale.js" /* webpackChunkName: "component---src-templates-sale-js" */)
}

